.body {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #000000;

  .description {
    width: 100%;
    height: 40px;
    background-color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Ubuntu, Ubuntu;
    font-weight: 400;
    font-size: 16px;
    color: #ffffff;
    line-height: 18px;
    text-align: center;
    font-style: normal;
  }

  .banner {
    width: 100%;
    height: auto;
    aspect-ratio: 1440/810;
    max-width: 1440px;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 65px;

    @media only screen and (max-width: 1279px) {
      padding: 0 20px;

      @media only screen and (max-width: 767px) {
        padding: 0;
        gap: 20px;
        aspect-ratio: unset;
        flex-direction: column;
      }
    }

    .video {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      object-fit: cover;
      object-position: center;

      @media only screen and (max-width: 767px) {
        position: relative;
        height: auto;
        aspect-ratio: 1440/810;
      }
    }

    .bannerContent {
      z-index: 9;
      width: 100%;
      max-width: 451px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media only screen and (max-width: 767px) {
        max-width: unset;
        padding: 0 20px;
      }

      .bannerTitle {
        font-size: 48px;
        font-family: Ubuntu, Ubuntu;
        font-weight: bold;
        color: #ffffff;
        line-height: 55px;

        @media only screen and (max-width: 767px) {
          font-size: 40px;
          line-height: 46px;
          text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
        }
      }

      .bannerDescription {
        margin-top: 24px;
        font-size: 24px;
        font-family: Ubuntu, Ubuntu;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.8);
        line-height: 28px;

        @media only screen and (max-width: 767px) {
          font-size: 24px;
          line-height: 28px;
        }
      }

      .bannerAction {
        margin-top: 48px;
        width: 200px;
        height: 48px;
        background: linear-gradient(
          135deg,
          #d09b37 0%,
          #ffe89c 50%,
          #d09b37 100%
        );
        border-radius: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;

        &:hover {
          cursor: pointer;
          background: #ffffff;
        }

        .bannerActionText {
          height: 18px;
          font-size: 16px;
          font-family: Ubuntu, Ubuntu;
          font-weight: bold;
          color: #000000;
          line-height: 18px;
        }

        .bannerActionIcon {
          width: 24px;
          height: 24px;
          fill: #000000;
        }
      }
    }
  }

  .founders {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 65px;
    margin-top: 160px;

    @media only screen and (max-width: 1279px) {
      padding: 0 20px;

      @media only screen and (max-width: 1023px) {
        margin-top: 80px;
      }
    }

    .foundersContent {
      width: 100%;
      max-width: 1310px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 40px;

      .founderTitle {
        width: 100%;
        text-align: center;
        font-size: 40px;
        font-family: Ubuntu, Ubuntu;
        font-weight: bold;
        color: #ffffff;
        line-height: 46px;
      }

      .founderList {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        border: 1px solid #4d4d4d;
        overflow: hidden;
        position: relative;

        @media only screen and (max-width: 1023px) {
          flex-direction: column;
          max-width: 560px;
        }

        .founderItem {
          flex: 1;
          height: 100%;
          padding: 20px;
          display: flex;
          flex-direction: column;

          @media only screen and (max-width: 1023px) {
            padding: 24px;
            flex: unset;
            width: 100%;
            height: auto;
          }

          &:nth-of-type(2) {
            border-left: 1px solid #4d4d4d;
            border-right: 1px solid #4d4d4d;

            @media only screen and (max-width: 1023px) {
              border-left: 0;
              border-right: 0;
              border-top: 1px solid #4d4d4d;
              border-bottom: 1px solid #4d4d4d;
            }
          }

          .founderItemImage {
            width: 100%;
            height: auto;
            object-fit: contain;
            object-position: center;
          }

          .founderBar {
            margin-top: 16px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .founderBarText {
              font-size: 32px;
              font-family: Ubuntu, Ubuntu;
              font-weight: bold;
              color: #ffffff;
              line-height: 37px;
              background: linear-gradient(
                135deg,
                #d09b37 0%,
                #ffe89c 50%,
                #d09b37 100%
              );
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }

            .founderBarIcon {
              width: 32px;
              height: 32px;
              fill: #ffffff;

              &:hover {
                cursor: pointer;
                opacity: 0.5;
              }
            }
          }

          .founderInfo {
            margin-top: 8px;
            width: 100%;
            font-size: 24px;
            font-family: Ubuntu, Ubuntu;
            font-weight: 400;
            color: #ffffff;
            line-height: 28px;
          }
        }
      }
    }
  }

  .team {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 65px;
    margin-top: 160px;

    @media only screen and (max-width: 1279px) {
      padding: 0 20px;

      @media only screen and (max-width: 1023px) {
        margin-top: 80px;
      }
    }

    .teamContent {
      width: 100%;
      max-width: 1310px;
      display: flex;
      align-items: center;
      border-radius: 20px;
      border: 1px solid #4d4d4d;
      overflow: hidden;
      background-image: url("../../assets/images/team_bg.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      @media only screen and (max-width: 1023px) {
        flex-direction: column;
      }

      .teamInfo {
        width: 50%;
        height: 100%;
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media only screen and (max-width: 1023px) {
          width: 100%;
          height: auto;
          justify-content: flex-start;
        }

        .teamInfoContent {
          max-width: 550px;
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 20px;

          .teamInfoImage {
            width: 184px;
            height: 64px;
            object-fit: contain;
            object-position: center;
          }

          .teamInfoText {
            width: 100%;
            font-size: 24px;
            font-family: Ubuntu, Ubuntu;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.8);
            line-height: 28px;

            @media only screen and (max-width: 1023px) {
              font-size: 16px;
              line-height: 18px;
            }
          }

          .teamInfoAction {
            width: 180px;
            height: 48px;
            background: linear-gradient(
              135deg,
              #d09b37 0%,
              #ffe89c 50%,
              #d09b37 100%
            );
            border-radius: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4px;

            &:hover {
              cursor: pointer;
              background: #ffffff;
            }

            .teamInfoActionText {
              height: 18px;
              font-size: 16px;
              font-family: Ubuntu, Ubuntu;
              font-weight: bold;
              color: #000000;
              line-height: 18px;
            }

            .teamInfoActionIcon {
              width: 24px;
              height: 24px;
              fill: #000000;
            }
          }
        }
      }

      .teamImage {
        width: 50%;
        height: auto;
        aspect-ratio: 600/538;
        object-fit: cover;
        object-position: center;

        @media only screen and (max-width: 1023px) {
          width: 100%;
          height: auto;
        }
      }
    }
  }

  .panel {
    margin-top: 40px;
    width: 100%;
    height: 640px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 56px;
    background-image: url("../../assets/images/binance_bg.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 20px;

    @media only screen and (max-width: 1023px) {
      margin-top: 20px;
      height: 360px;
    }

    .panelText {
      width: 100%;
      text-align: center;
      font-size: 48px;
      font-family: Ubuntu, Ubuntu;
      font-weight: bold;
      color: #ffffff;
      line-height: 55px;

      @media only screen and (max-width: 1023px) {
        font-size: 32px;
        line-height: 37px;
      }
    }

    .panelImage {
      width: 387px;
      height: 100px;
      object-fit: contain;
      object-position: center;

      @media only screen and (max-width: 1023px) {
        width: 248px;
        height: 64px;
      }
    }
  }
}
