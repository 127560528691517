@font-face {
  font-family: "Ubuntu-Bold";
  src: url("./assets/fonts/Ubuntu-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Ubuntu-BoldItalic";
  src: url("./assets/fonts/Ubuntu-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Ubuntu-Italic";
  src: url("./assets/fonts/Ubuntu-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "Ubuntu-Light";
  src: url("./assets/fonts/Ubuntu-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Ubuntu-LightItalic";
  src: url("./assets/fonts/Ubuntu-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Ubuntu-Medium";
  src: url("./assets/fonts/Ubuntu-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Ubuntu-MediumItalic";
  src: url("./assets/fonts/Ubuntu-MediumItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Ubuntu-Regular";
  src: url("./assets/fonts/Ubuntu-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Ubuntu";
  src: url("./assets/fonts/Ubuntu-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Gravity";
  src: url("./assets/fonts/Gravity.ttf") format("truetype");
}

@font-face {
  font-family: "UbuntuMono-Bold";
  src: url("./assets/fonts/UbuntuMono-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "UbuntuMono-BoldItalic";
  src: url("./assets/fonts/UbuntuMono-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "UbuntuMono-Italic";
  src: url("./assets/fonts/UbuntuMono-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "UbuntuMono-Regular";
  src: url("./assets/fonts/UbuntuMono-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "UbuntuMono";
  src: url("./assets/fonts/UbuntuMono-Regular.ttf") format("truetype");
}

* {
  box-sizing: border-box;

  ::-webkit-scrollbar {
    display: none;
  }
}

body {
  margin: 0;
  padding: 0;
  background-color: #0f1214;
  width: 100vw;
  overflow-x: hidden;
}

#root {
  margin: 0;
  padding: 0;
  background-color: #0f1214;
  width: 100vw;
  overflow-x: hidden;
}
