.body {
  width: 100%;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 65px;
  background-color: #000000;

  @media only screen and (max-width: 1279px) {
    padding: 0 20px;

    @media only screen and (max-width: 767px) {
      top: 70px;
    }
  }

  .container {
    width: 100%;
    height: 100%;
    max-width: 1310px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .logo {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      cursor: pointer;

      .logoImage {
        width: 146px;
        height: 48px;
        object-fit: contain;
        object-position: center;
      }

      .logoText {
        font-family: Gravity;
        font-weight: 400;
        font-size: 24px;
        color: #ffffff;
        line-height: 29px;
        text-align: left;
        font-style: normal;

        @media only screen and (max-width: 767px) {
          display: none;
        }
      }
    }

    .list {
      display: flex;
      align-items: center;
      gap: 24px;

      @media only screen and (max-width: 767px) {
        gap: 20px;
      }

      .item {
        width: 24px;
        height: 24px;
        fill: #fff;

        &:hover {
          cursor: pointer;
          opacity: 0.5;
        }
      }
    }

    .divider {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 1px;
      border: 0;
      background: linear-gradient(
        90deg,
        #13181a 0%,
        #4c4c4c 50%,
        #121618 100%,
        #101416 100%,
        #0d1012 100%,
        #080a0b 100%
      );
    }
  }
}
