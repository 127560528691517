.body {
  width: 100%;
  height: 188px;
  background-color: #0f1214;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0 65px;

  @media only screen and (max-width: 1279px) {
    padding: 0 20px;

    @media only screen and (max-width: 767px) {
      height: 280px;
    }
  }

  .container {
    width: 100%;
    max-width: 1310px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 767px) {
      flex-direction: column;
    }

    .content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media only screen and (max-width: 767px) {
        flex-direction: column;
      }

      .logo {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        cursor: pointer;

        .logoImage {
          width: 146px;
          height: 48px;
          object-fit: contain;
          object-position: center;
        }

        .logoText {
          font-family: Gravity;
          font-weight: 400;
          font-size: 24px;
          color: #ffffff;
          line-height: 29px;
          text-align: left;
          font-style: normal;
        }
      }

      .list {
        display: flex;
        align-items: center;
        gap: 24px;

        @media only screen and (max-width: 767px) {
          width: 100%;
          justify-content: center;
          margin-top: 40px;
        }

        .item {
          width: 24px;
          height: 24px;
          fill: #fff;

          @media only screen and (max-width: 767px) {
            width: 36px;
            height: 36px;
          }

          &:hover {
            cursor: pointer;
            opacity: 0.5;
          }
        }
      }
    }

    .copyright {
      position: absolute;
      font-size: 16px;
      font-family: Ubuntu, Ubuntu;
      font-weight: 400;
      color: #ffffff;
      line-height: 18px;
      text-align: center;

      @media only screen and (max-width: 767px) {
        position: relative;
        margin-top: 30px;
      }
    }
  }
}
