.verification {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 0 20px;
  z-index: 99;
  background-color: #212426;

  @media only screen and (max-width: 767px) {
    height: 70px;
  }

  .verificationIcon {
    width: 20px;
    min-width: 20px;
    max-width: 20px;
    height: 20px;
    fill: #00bf70;
  }

  .verificationValue {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .verificationLabel {
      font-size: 16px;
      font-family: Ubuntu, Ubuntu;
      font-weight: 400;
      color: #ffffff;
      line-height: 18px;
    }

    .verificationText {
      font-size: 16px;
      font-family: Ubuntu, Ubuntu;
      font-weight: 400;
      color: #ffffff;
      line-height: 18px;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }

      .verificationTexts {
        color: #00bf70;
      }
    }
  }
}
